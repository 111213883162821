export const prepareProgramRegistrationInfo = {
  methods: {
    isActiveRegistrationByRole(role, program) {
      if (['child', 'parent'].includes(role)) {
        return !this.availableCamperRegistration
      }

      if (role === 'staff') {
        return !program.is_active_for_staff
      }

      return false
    },
    getProgramRegistrationLinkByRole(role, program) {
      if (['child', 'staff'].includes(role)) {
        return {name: 'application-create', params: { id: program.id }}
      }

      if (role === 'parent') {
        return {name: 'application-select-child', params: { id: program.id }}
      }
    },
    isDisabledRegistrationByGender(role, gender, program) {
      if (!gender) {
        return false
      }

      if (['staff', 'parent'].includes(role)) {
        return false
      }

      if (role === 'child') {
        return this.checkProgramRegistrationIsDisableByGender(gender, program.gender)
      }

      return false
    },
    checkProgramRegistrationIsDisableByGender(gender, programGender) {
      const maleValues = ['boy', 'male']
      const femaleValues = ['girl', 'female']

      if (!gender) {
        return false
      }

      if (programGender === 'Boys' && maleValues.indexOf(gender.toLowerCase()) === -1) {
        return true
      }

      if (programGender === 'Girls' && femaleValues.indexOf(gender.toLowerCase()) === -1) {
        return true
      }

      return false
    },
    availableRegularLogin(chosenRole, authSettings) {
      if (chosenRole === 'staff' && authSettings.enable_regular_login_for_staff) {
        return true
      }
      if (chosenRole && chosenRole !== 'staff' && authSettings.enable_regular_login_for_parents) {
        return true
      }
      return false
    },
    availableRegularRegistration(chosenRole, authSettings) {
      if (chosenRole === 'staff' && authSettings.enable_regular_registration_for_staff) {
        return true
      }
      if (chosenRole && chosenRole !== 'staff' && authSettings.enable_regular_registration_for_parents) {
        return true
      }
      return false
    },
    availableOauthLogin(chosenRole, authSettings) {
      if (chosenRole === 'staff' && authSettings.enable_oauth_login_for_staff) {
        return true
      }
      if (chosenRole && chosenRole !== 'staff' && authSettings.enable_oauth_login_for_parents) {
        return true
      }
      return false
    },
    availableOauthRegistration(chosenRole, authSettings) {
      if (chosenRole === 'staff' && authSettings.enable_oauth_registration_for_staff) {
        return true
      }
      if (chosenRole && chosenRole !== 'staff' && authSettings.enable_oauth_registration_for_parents) {
        return true
      }
      return false
    },
  },
}

export const _ = null
